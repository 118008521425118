import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GroupComponent, TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Lucide from "../../../base-components/Lucide";
import Dialog from "../../../base-components/Headless/Dialog";
import jsPDF from "jspdf";
import * as xlsx from "xlsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import FormLabel from "../../../base-components/Form/FormLabel";
import FormInput from "../../../base-components/Form/FormInput";
import clsx from "clsx";
import FormSelect from "../../../base-components/Form/FormSelect";
import Litepicker from "../../../base-components/Litepicker";
import DocumentView from "../sharedComponent/DocumentView";
import GuarantorView from "../sharedComponent/GuarantorView";
import FullDetailsComponent from "../sharedComponent/FullDetailsView";
import BankDetails from "../sharedComponent/BankDetails";
export default function Main() {
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useLoader();
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [deleteConfirmationModal1, setDeleteConfirmationModal1] = useState(false);
    const tableRef = createRef<HTMLDivElement>();
    const tabulator = useRef<Tabulator>();
    const [id, setId] = useState(0);
    const sendButtonRef = useRef();
    const deleteButtonRef = useRef(null);
    const [headerFooterModalPreview, setHeaderFooterModalPreview] = useState(false);
    const [successModalPreview, setSuccessModalPreview] = useState(false);

    const [modal, setModal] = useState({ msg: '', title: '', type: '' });
    const [basicModalPreview, setBasicModalPreview] = useState(false)
    const [totPages, setTotPages] = useState(0)
    const [agentName, setAgentName] = useState<any>()
    //const [groupName, setGroupName] = useState('')
    const [agentId, setAgentId] = useState('')
    const [agentIdS, setAgentIdS] = useState('')
    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [areaId, setAreaId] = useState('')
    const [groupidProcess, setGroupidProcess] = useState('')
    const [isLeader, setIsleader] = useState('')
    const { agentid } = useParams();
    
    const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

    const [dateRange, setDateRange] = useState(dateRange1);
    const [isUpdate, setIsUpdate] = useState(false);
    const [invalidAlert, setInvalidAlert] = useState(false);
    const [castes, setCastes] = useState([]);
    const initClient = { name: '', mobile: '', address: '' }
    const [agent, setAgent] = useState(initClient)
   // const guarantors = [
    //    {
    //        name: 'Amol Patil',
    //        aadhaarNumber: '1234-5678-9101',
    //        panNumber: 'ABCDE1234F',
    //        relation: 'Brother',
    //        address: '123 Street, City, State, Country',
    //        mobile: '+1234567890',
    //    },
    //    {
    //        name: 'Mujaffar Pathan',
    //        aadhaarNumber: '2234-5678-9101',
    //        panNumber: 'BCDEA2345G',
    //        relation: 'Sister',
    //        address: '456 Avenue, City, State, Country',
    //        mobile: '+0987654321',
    //    },
    //];
    //const fullDetails = {
    //    name: 'John Doe',
    //    mobile: '+1234567890',
    //    address: '123 Street, City, State, Country',
    //    relativeMobile: '+0987654321',
    //    reason: 'Personal Loan',
    //    returnInstallment: 'Monthly',
    //    otherLoan: 'Home Loan',
    //    cibilScore: 750,
    //    familyIncome: '$50,000',
    //    yearlyIncome: '$100,000',
    //    ownHouse: true,
    //    ownBusiness: false,
    //    totalCapital: '$200,000',
    //};
    const [superlargeModalSizePreview, setSuperlargeModalSizePreview] = useState(false);
    const [superlargeModalSizePreview1, setSuperlargeModalSizePreview1] = useState(false);
    const [superlargeModalSizePreview2, setSuperlargeModalSizePreview2] = useState(false);
    const [superlargeModalSizePreview3, setSuperlargeModalSizePreview3] = useState(false);
    //const [clientData, setClientData] = useState([]);
    const [agentNameS, setAgentNameS] = useState<any>()
    const [areaName, setAreaName] = useState<any>()
    const [memberId, setMemberId] = useState('');
    const schema = yup.object().shape({
        //id: yup.number(),
        name: yup.string().trim().required().min(2),
        mobile: yup.string().required('Caste is required').matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
        //.matches(/^[1-9]\d*$/, { excludeEmptyString: true, message: 'Caste is required', }),
        address: yup.string().required().min(4),
        //remobile: yup.string().required().min(2),
        //email: yup.string().email(),
        //mobile1: yup.string().required().min(10).max(10)
        //	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
        //mobile2: yup.string().min(10).max(10)//.required()
        //	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
    }).required();

    const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
        criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
        values: agent, reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    })
    var deleteIcon = function () { return '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>'; };

    useEffect(() => {//setSuccessModalPreview(true)
        initTabulator();
        reInitOnResizeWindow();
        getCategories();
        getAgentName()
        getAreaName()
    }, []);
    const buttonARef = useRef(null);
    const handleButtonBClick = () => {
        if (buttonARef.current) {
            buttonARef.current.click(); // Trigger click event of Button A
        }
    };
    const redrow = () => {
        if (tabulator.current) {
            tabulator.current.setData('/api/enquiry-list', { from: from, to: to, agentId: agentIdS, areaId: areaId })
            //console.log('redraw')
        }
      
    }
    //const redrow = () => {
    //    initTabulator();
    //    reInitOnResizeWindow();
    //    getCategories();
    //}
    const initTabulator = () => {
        if (tableRef.current) {
            tabulator.current = new Tabulator(tableRef.current, {//groupBy: "type",//data: dataList,
                
                ajaxFiltering: true, groupBy: 'groupId', groupHeader: (value: any, count: any, data: any, group: GroupComponent) => {
                    //console.log(value, count, data, group.getKey(), data)
                    return `     ${data[0].groupName}   
                    <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-32 md:w-[22] sm:w-auto" onclick="document.getElementById('h1').value ='${group.getKey()}',document.getElementById('addmember').click();">
        Add Member
    </button>
    <button class="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-32 sm:w-auto" onclick="document.getElementById('h2').value ='${group.getKey()}';document.getElementById('process').click();">
        Process To Loan
    </button>
    <button class="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-32 sm:w-auto" onclick="document.getElementById('h1').value ='${group.getKey()}';document.getElementById('delete').click();">
        Delete Group
    </button>
</div>`//onclick="document.getElementById('h1').value ='${group.getKey()}', document.getElementById('addmember').click()"
                },
                ajaxURL: "/api/enquiry-list", paginationMode: "remote", filterMode: "remote", sortMode: "remote",
                //ajaxParams: { from: from, to: to, agentId: agentIdS, areaId: areaId },
                movableColumns: true, ajaxResponse: (_url, p, res) => {
                    //console.log('hello');
                    setTotPages(res.last_page)
                    const page = p.page, size = p.size
                    const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));
                   
                    res.data = processedData
                    return res
                }, //placeholderHeaderFilter:"ijn",

                pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
                reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
                columns: [
                    { title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
                    { title: "Name", field: "name", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Name", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    { title: "Mobile", field: "mobile", print: true, minWidth: 150, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    { title: "Date", field: "date",  minWidth: 200,  print: true, hozAlign: "center", headerHozAlign: 'center', editable: true, editor: "input", validator: "required", },
                    { title: "Address", field: "address", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    { title: "Leader", field: "isLeader", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    { title: "Agent", field: "agentName", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    { title: "Area", field: "area", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
                    {
                        title: 'View Documents', minWidth: 100,
                        formatter: () => '<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Document</button>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setMemberId(planData.id);
                            setSuperlargeModalSizePreview(true);
                           // navigate(`/finance/expenses/${planData.id}`);
                        },
                    },
                    {
                        title: 'Guarantor', minWidth: 100,
                        formatter: () => '<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Guarantor</button>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setMemberId(planData.id);
                            
                            setSuperlargeModalSizePreview1(true);
                         
                            //navigate(`/finance/expenses/${planData.id}`);
                        },
                    },
                    {
                        title: 'Details', minWidth: 100,
                        formatter: () => '<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Details</button>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setMemberId(planData.id);
                            setSuperlargeModalSizePreview2(true);
                            //navigate(`/finance/expenses/${planData.id}`);
                        },
                    },
                    {
                        title: 'Bank Details', minWidth: 100,
                        formatter: () => '<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Bank</button>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setMemberId(planData.id);
                            setSuperlargeModalSizePreview3(true);
                            //navigate(`/finance/expenses/${planData.id}`);
                        },
                    },
                    {
                        title: 'Delete', minWidth: 80,
                        formatter: () => '<i data-lucide="trash2" class="w-8 h-8 mr-1 text-danger"></i>',
                        cellClick: (e, cell) => {
                            const planData = cell.getRow().getData();
                            setId(planData.id);
                            setDeleteConfirmationModal(true);
                        },
                    },
                ],
            });
        }

        tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
    };
    //pending to temp
    
    async function deleteprocess() {
        try {
            showLoader()//body: JSON.stringify(process), 
            const f = await fetch(`/api/enquiry-list/${id}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json', }, })
            if (f.ok) {
                const j = await f.json()
                if (j.e) { }
                else {
                    tabulator.current.setData();
                    setDeleteConfirmationModal(false);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    const reInitOnResizeWindow = () => {
        window.addEventListener("resize", () => {
            if (tabulator.current) {
                tabulator.current.redraw();
                createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
            }
        });
    };

    const handleChange = (e) => {
        const name = e.target.value
        setAgent((recentData) => ({ ...recentData, [e.target.name]: name }))

    }
    function getFormValuesAsJson(form: HTMLFormElement): any {
        const formData = new FormData(form);
        const values: any = {};

        for (const entry of formData.entries()) {
            const [name, value] = entry;
            if (name === '') continue;
            if (Object.prototype.hasOwnProperty.call(values, name)) {
                if (typeof values[name] === 'string') values[name] = [values[name], value];
                else values[name].push(value);
            } else values[name] = value;
        }
        return values;
    }
    const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            const result = await trigger()
            if (result) {
                showLoader()
                setAgent(getFormValuesAsJson(event.target))
                //...client,
                const gn = (document.getElementById('h1') as HTMLInputElement).value
                const cl = { name: agent.name, mobile: agent.mobile, isLeader: isLeader, address: agent.address, groupId: gn, agentId: agentId }
                //const ts = { id: Number(eventId), eventName: agent.eventName, eventAddress: agent.eventAddress, }
               // console.log(agentId)
                const d = JSON.stringify(cl)

                const f = await fetch('/api/enquiry-master', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
                if (f.ok) {
                    const data = await f.json();
                    /*console.log("success", data);*/

                    setBasicModalPreview(false);
                    setInvalidAlert(true);
                    initTabulator();
                } else console.error(f.status, f.statusText)
            } else console.log('validation error')
        } catch (error) { console.error(error) } finally { hideLoader() }
    }
    //agent selecter
    async function getCategories() {
        try {

            //showLoader()
           // console.log("helo");
            const f = await fetch('/api/agent-name')
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    //  setExpenseName(r.d)
                    setAgentName(r.d)
                    //console.log(r.d);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    
   
    async function deleteGroup() {
        try {
            showLoader()//body: JSON.stringify(process), 
            const gn = (document.getElementById('h1') as HTMLInputElement).value
            console.log({ gn })
            const f = await fetch(`/api/group-list/${gn}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json', }, })
            if (f.ok) {
                const j = await f.json()
                if (j.e) { }
                else {
                    tabulator.current.setData();
                    setDeleteConfirmationModal1(false);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }

    const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
    const onExportPdf = () => {
        if (tabulator.current) {
            const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
            doc.autoTable({
                head: [['Sr.', 'Name', 'Mobile', 'Date', 'Address', 'Leader/Member','Agent','Group Name']],
                body: tabulator.current.getData().map(row => [row.srno, row.name, row.mobile, row.date, row.address, row.isLeader, row.agentName,row.groupName]),
            });

            doc.save('Expenses.pdf')
        }
        // tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
    };
    const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "enquirydata.csv"); };
    const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "enquirydata.json"); };
    const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "enquirydata.html", { style: true, }); };

    const onExportXlsx = () => {
        if (tabulator.current) {
            (window as any).XLSX = xlsx;
            tabulator.current.download("xlsx", "data.xlsx", { sheetName: "Products", });
        }
    };
    useEffect(() => {
        //console.log({ groupidProcess })
        if (groupidProcess) navigate(`/finance/loan-users/${groupidProcess}`); 
        setFrom('')
        setTo('')
    }, [groupidProcess]);
    async function getAgentName() {
        try {

            showLoader()
            const f = await fetch('/api/get-agent-name')
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    setAgentNameS(r.d)
                    //console.log(agentName);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    async function getAreaName() {
        try {

            showLoader()
            const f = await fetch('/api/get-area-name')
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    setAreaName(r.d)
                    //console.log(agentName);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    return <><input type='hidden' id='h1' /><input type='hidden' id='h2' value={groupidProcess} />
        <div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
            <h2 className="mr-auto text-lg font-medium">Enquiry List</h2>
        </div>
        <div >
            <Button style={{ display: 'none' }} id="delete" onClick={() => { setDeleteConfirmationModal1(true); }} ></Button>
            <Button style={{ display: 'none' }}  id="addmember" onClick={() => { setBasicModalPreview(true); }}></Button>
            <Button style={{ display: 'none' }} id="process" onClick={() => {
                const pro = (document.getElementById('h2') as HTMLInputElement).value
                setGroupidProcess(pro);
                console.log({ pro })
            }}></Button>
            
        </div>
        
        <div className="grid grid-cols-12 gap-4 gap-y-3">
            <div className="col-span-12 lg:col-span-5">
                <FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
                <Litepicker
                    id="modal-datepicker-1"
                    value={from}
                    onChange={setFrom}
                    options={{
                        autoApply: false, format: 'DD/MM/YYYY',
                        showWeekNumbers: true,
                        dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true,
                        },
                    }}
                />
            </div>
            <div className="col-span-12 lg:col-span-5">
                <FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
                <Litepicker
                    id="modal-datepicker-2"
                    value={to}
                    onChange={setTo}
                    options={{
                        autoApply: false, format: 'DD/MM/YYYY',
                        showWeekNumbers: true,
                        dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: true,
                        },
                    }}
                />
            </div>
            <div className="col-span-12 lg:col-span-2 flex items-end">
                    <Button className="mr-2 w-full" variant="primary" onClick={() => redrow() }>Search</Button>
            </div>
        </div>
        <div className="grid grid-cols-12 gap-4 gap-y-3">
            <div className="col-span-12 lg:col-span-4">
                <div className="input-form mb-5">
                    <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Agent Name</FormLabel>

                    <FormSelect id='sCat' onChange={(e) => setAgentIdS(e.currentTarget.value)} name='categoryId' value={agentIdS} aria-label="Default select example">
                        <option value='0'>Select Agent</option>
                        {(agentNameS != undefined && agentNameS && agentNameS.length > 0) ? agentNameS.map((cat: any) => (
                            <option value={cat.id} key={cat.id}>{cat.name}</option>
                        )) : <option>Nothing to show</option>}
                    </FormSelect>
                </div>
            </div>
            <div className="col-span-12 lg:col-span-2 flex items-end mb-5">
                <Button className="mr-2 w-full" variant="primary" onClick={()=>redrow()}>Search</Button>
            </div>
            <div className="col-span-12 lg:col-span-4">
                <div className="input-form mb-5">
                    <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Area Name</FormLabel>

                    <FormSelect id='sCat' onChange={(e) => setAreaId(e.currentTarget.value)} name='categoryId' value={areaId} aria-label="Default select example">
                        <option value='0'>Select Area</option>
                        {(areaName != undefined && areaName && areaName.length > 0) ? areaName.map((cat: any) => (
                            <option value={cat.id} key={cat.id}>{cat.name}</option>
                        )) : <option>Nothing to show</option>}
                    </FormSelect>
                </div>
            </div>
            <div className="col-span-12 lg:col-span-2 flex items-end mb-5">
                    <Button className="mr-2 w-full" variant="primary" onClick={()=>redrow()}>Search</Button>
                   
            </div>
        </div>
        <div className="p-5 mt-5 intro-y box">
            <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
                <div className="xl:flex sm:mr-auto">

                    <Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
                    <Menu className="w-1/2 sm:w-auto">
                        <Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
                            <Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
                            <Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
                        </Menu.Button>
                        <Menu.Items className="w-40">
                            <Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
                            <Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
                            <Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
                            <Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
                            <Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
                        </Menu.Items>
                    </Menu>
                </div>
                <Button onClick={() => {

                    if (tabulator.current) {
                        tabulator.current.setData()
                        setTo('')
                        setFrom('')
                        setAgentIdS('')
                        setAreaId('')
                    }

                }}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
            </div>
            <div className="overflow-x-auto scrollbar-hidden">
                <div id="tabulator" ref={tableRef} className="mt-5"></div>
            </div>
            <div className='flex w-full'>
                <span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
            </div>
        </div>
        <Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
            <Dialog.Panel>
                <div className="p-5 text-center">
                    <Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
                    <div className="mt-5 text-3xl">Are you sure?</div>
                    <div className="mt-2 text-slate-500">
                        Delete ? <br />
                        This process cannot be undone.
                    </div>
                </div>
                <div className="px-5 pb-8 text-center">
                    <Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
                    <Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteprocess} >Delete</Button>
                </div>
            </Dialog.Panel>
        </Dialog>
        <Dialog open={deleteConfirmationModal1} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal1(false); }}>
            <Dialog.Panel>
                <div className="p-5 text-center">
                    <Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
                    <div className="mt-5 text-3xl">Are you sure?</div>
                    <div className="mt-2 text-slate-500">
                        Delete ? <br />
                        This process cannot be undone.
                    </div>
                </div>
                <div className="px-5 pb-8 text-center">
                    <Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal1(false); }} className="w-24 mr-1">Cancel</Button>
                    <Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteGroup} >Delete</Button>
                </div>
            </Dialog.Panel>
        </Dialog>

        <Dialog open={basicModalPreview} onClose={() => { setBasicModalPreview(false); }}  >
            <Dialog.Panel className="p-10 text-center">
                <div className="p-5 intro-y box">
                    <form className="validate-form" onSubmit={onSubmit2}>
                        <div className="input-form mb-5">
                            <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Member Name
                                {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                            </FormLabel>
                            <FormInput id='fiName' {...register("name")} value={agent.name} placeholder="Enter Agnet Name"
                                onChange={(e) => setAgent({ ...agent, name: e.target.value })} className={clsx({ "border-danger": errors.name })} autoComplete='off' />

                        </div>

                        <div className="col-span-12 intro-y lg:col-span-6 mb-5">
                            <div className="input-form">
                                <FormLabel className="flex flex-col w-full sm:flex-row">Watsapp Number
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
                                    {/*	Required at least 5 character*/}
                                    {/*</span>*/}
                                </FormLabel>
                                <FormInput {...register("mobile")} type="text" value={agent.mobile} disabled={isUpdate}
                                    onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.mobile })}
                                    placeholder="Enter Number" />
                                {errors.mobile && (<div className="mt-2 text-danger"> {typeof errors.mobile.message === "string" && errors.mobile.message} </div>)}
                            </div>
                        </div>
                        <div className="col-span-12 intro-y lg:col-span-6 mb-5">
                            <div className="input-form">
                                <FormLabel className="flex flex-col w-full sm:flex-row">Address
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
                                    {/*	Required at least 5 character*/}
                                    {/*</span>*/}
                                </FormLabel>
                                <FormInput {...register("address")} type="text" value={agent.address}
                                    onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.address })}
                                    placeholder="Enter Address" />
                                {errors.address && (<div className="mt-2 text-danger"> {typeof errors.address.message === "string" && errors.address.message} </div>)}
                            </div>
                        </div>

                        <div className="col-span-12 intro-y lg:col-span-6 mb-5">
                            <div className="input-form">
                                <FormLabel className="flex flex-col w-full sm:flex-row">Leader
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
                                    {/*	Required at least 5 character*/}
                                    {/*</span>*/}
                                </FormLabel>
                                <FormSelect id='sCat' onChange={(e) => setIsleader(e.target.value)} name='categoryId' value={isLeader} aria-label="Default select example">
                                    <option value='0'>No</option>
                                    <option value='1'>Yes</option>
                                </FormSelect>
                            </div>
                        </div>
                        <div className="input-form mb-5">
                            <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Agent Name
                                {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                            </FormLabel>
                            {/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
                            {/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
                            <FormSelect id='sCat' onChange={(e) => setAgentId(e.target.value)} name='categoryId' value={agentId} aria-label="Default select example">
                                <option value='0'>Select Type</option>
                                {(agentName != undefined && agentName && agentName.length > 0) ? agentName.map((cat: any) => (
                                    <option value={cat.id} key={cat.id}>{cat.agentName}</option>
                                )) : <option>Nothing to show</option>}
                            </FormSelect>
                        </div>
                        <Button variant="primary" type="submit" className="m-2">Add Member</Button>
                    </form>
                </div>
            </Dialog.Panel>
        </Dialog>
        {/*document Dialog*/}
        <Dialog size="xl" open={superlargeModalSizePreview} onClose={() => { setSuperlargeModalSizePreview(false); }} >
            <Dialog.Panel className="p-10 text-center">
                <DocumentView id={memberId} />
            </Dialog.Panel>
        </Dialog>
        {/*guarantor Dialog*/}
        <Dialog size="xl" open={superlargeModalSizePreview1} onClose={() => { setSuperlargeModalSizePreview1(false);}} >
            <Dialog.Panel className="p-10 text-center">
                <GuarantorView id={memberId} />   
            </Dialog.Panel>
        </Dialog>
        {/*details Dialog*/}
        <Dialog size="xl" open={superlargeModalSizePreview2} onClose={() => { setSuperlargeModalSizePreview2(false); }} >
            <Dialog.Panel className="p-10 text-center">
                <FullDetailsComponent id={memberId} />
            </Dialog.Panel>
        </Dialog>
        <Dialog size="xl" open={superlargeModalSizePreview3} onClose={() => { setSuperlargeModalSizePreview3(false); }} >
            <Dialog.Panel className="p-10 text-center">
                <BankDetails id={memberId} />
            </Dialog.Panel>
        </Dialog>
    </>
}