import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Litepicker from "../../../base-components/Litepicker";
import { FormLabel, FormInput, FormTextarea, FormInline, } from "../../../base-components/Form";
import Notification from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import Toastify from "toastify-js";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import Button from "../../../base-components/Button";
import { Dialog } from "../../../base-components/Headless";
import { FormSelect } from "../../../base-components/Form";
import { useForm } from "react-hook-form";
import './client.css'
import { useLoader } from "../../../base-components/Loader";

function Main() {
	const { showLoader, hideLoader } = useLoader();
	const navigate = useNavigate();
	const { agentid } = useParams();
	const eventId = useSelector((state: RootState) => state.eventId.eventId);
	const [btnText, setBtnText] = useState('Create Agent');
	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

	const [dateRange, setDateRange] = useState(dateRange1);
	const [isUpdate, setIsUpdate] = useState(false);
	const [invalidAlert, setInvalidAlert] = useState(false);
	const [castes, setCastes] = useState([]);
	const initClient = { name: '', mobile: '',  address: '', password: '', email: '' }
	const [agent, setAgent] = useState(initClient)

	//const [clientData, setClientData] = useState([]);
	const [records, setRecords] = useState([])
	const [showList, setShowList] = useState(true);

	const schema = yup.object().shape({
		//id: yup.number(),
		name: yup.string().trim().required().min(2),
		mobile: yup.string().required('Caste is required').matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
			//.matches(/^[1-9]\d*$/, { excludeEmptyString: true, message: 'Caste is required', }),
		address: yup.string().required().min(4),
		password: yup.string().required().min(2),
		email: yup.string().email(),
		//mobile1: yup.string().required().min(10).max(10)
		//	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
		//mobile2: yup.string().min(10).max(10)//.required()
		//	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
	}).required();

	const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
		criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
		values: agent, reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	})

	//const handleListItemClick = async (record: any) => {
	//	try {
	//		showLoader()
	//		localStorage.clear()
	//		setAgent(recentData => ({ ...recentData, name: record.name }))
	//		setShowList(false)
	//		const f = await fetch(`/api/client?id=${record.id}`)
	//		if (f.ok) {
	//			const data = await f.json()
	//			if (data == null || data == undefined) setAgent(initClient)
	//			else setAgent({ agent, ...data })
	//			localStorage.setItem('clientId', String(data.id))
	//		} else console.error(f.status, f.statusText)
	//	} catch (err) { console.error(err) } finally { hideLoader() }
	//}

	const handleChange = (e) => {
		const name = e.target.value
		setAgent((recentData) => ({ ...recentData, [e.target.name]: name }))
		//fetchAutoCompleteNames(name.toString().trim())
		//setRecords(clientData.filter(f => f.name.substring(0, 3).toLowerCase().includes(e.target.value)))
		//setShowList(true);
		//if (e.target.value == "") {
		//	setShowList(false);
		//	setClient(initClient);
		//}
		//if (records.length == 0) {
		//	setShowList(false);
		//	setClient((recentData) => ({ ...recentData, [e.target.name]: e.target.value }));
		//}
	}

	//const fetchClient = async (eventId: string | null) => {
	//	try {
	//		showLoader()
	//		localStorage.clear()
	//		const f = await fetch(`/api/event_client?id=${eventId}`)
	//		if (f.ok) {
	//			const data = await f.json()
	//			if (data == null || data == undefined) setAgent(initClient)
	//			else {
	//				setAgent(data)//setRecords(clientData)
	//				localStorage.setItem('clientId', String(data.id))
	//			}
	//		} else console.error(f.status, f.statusText)
	//	} catch (err) { console.error(err) } finally { hideLoader() }
	//}

	function getFormValuesAsJson(form: HTMLFormElement): any {
		const formData = new FormData(form);
		const values: any = {};

		for (const entry of formData.entries()) {
			const [name, value] = entry;
			if (name === '') continue;
			if (Object.prototype.hasOwnProperty.call(values, name)) {
				if (typeof values[name] === 'string') values[name] = [values[name], value];
				else values[name].push(value);
			} else values[name] = value;
		}
		return values;
	}

	const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			const result = await trigger()
			if (result) {
				showLoader()
				setAgent(getFormValuesAsJson(event.target))
				//...client,
				const cl = {  name: agent.name, mobile: agent.mobile, password: agent.password, address: agent.address, email: agent.email ,id:agentid}
				//const ts = { id: Number(eventId), eventName: agent.eventName, eventAddress: agent.eventAddress, }
				console.log(cl)
				const d = JSON.stringify( cl)

				const f = await fetch('/api/agent-creation', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
				if (f.ok) {
					const data = await f.json();
					/*console.log("success", data);*/
					setInvalidAlert(true);
				} else console.error(f.status, f.statusText)
			} else console.log('validation error')
		} catch (error) { console.error(error) } finally { hideLoader() }
	}


	const getdata = async () => {
		if (agentid) {
			try {
				setIsUpdate(true)
				const f = await fetch(`/api/agent-creation/${agentid}`)
				if (f.ok) {
					const d = await f.json();

					
					console.log(d.i)

					setAgent({ ...agent, name: d.i.name, mobile: d.i.mobile,address:d.i.address,email:d.i.email,password:d.i.password })
					setBtnText('Update Agent')
				} else console.error(f.status, f.statusText)
			} catch (e) { } finally {
				hideLoader()
			}
		}
	}
	

	useEffect(() => {
		getdata();
	}, [])

	

	return (
		<>
			
			<div className="flex items-center mt-8 intro-y">
				<h1 className="mr-auto text-lg font-medium">Agent Creation</h1>
				<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
					<Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/agent-list')} >Agent List</Button>
				</div>
			</div>
			<div className="grid grid-cols-12 gap-6 mt-5">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="p-5 intro-y box">
						<form className="validate-form" onSubmit={onSubmit2}>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Agent Name
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								<FormInput id='fiName' {...register("name")}  value={agent.name} placeholder="Enter Agnet Name"
									onChange={(e) => setAgent({ ...agent, name: e.target.value })} className={clsx({ "border-danger": errors.name })} autoComplete='off' />
								
							</div>
							
							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Watsapp Number
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormInput {...register("mobile")} type="text" value={agent.mobile} disabled={isUpdate}
										onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.mobile })}
										placeholder="Enter Number" />
									{errors.mobile && (<div className="mt-2 text-danger"> {typeof errors.mobile.message === "string" && errors.mobile.message} </div>)}
								</div>
							</div>
							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Address
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormInput {...register("address")} type="text" value={agent.address}
										onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.address })}
										placeholder="Enter Address" />
									{errors.address && (<div className="mt-2 text-danger"> {typeof errors.address.message === "string" && errors.address.message} </div>)}
								</div>
							</div>
							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Email
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormInput {...register("email")} type="text" value={agent.email}
										onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.email })}
										placeholder="Enter Email" />
									{errors.email && (<div className="mt-2 text-danger"> {typeof errors.email.message === "string" && errors.email.message} </div>)}
								</div>
							</div>
							<div className="col-span-12 intro-y lg:col-span-6 mb-5">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Password
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormInput {...register("password")} type="text" value={agent.password}
										onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.password })}
										placeholder="Enter Password" />
									{errors.password && (<div className="mt-2 text-danger"> {typeof errors.password.message === "string" && errors.password.message} </div>)}
								</div>
							</div>

							<Button variant="primary" type="submit" className="m-2">{btnText}</Button>
						</form>
					</div>
					
					<Dialog id='failed-notification-content' staticBackdrop open={invalidAlert} onClose={() => { setInvalidAlert(false); }} >
						<Dialog.Panel className="px-5 py-10">
							<div className="text-center">
								<div className="mb-5">{btnText} Successfully...!</div>
								<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {setInvalidAlert(false); navigate('/finance/agent-list')}}>Ok</Button>
							</div>
						</Dialog.Panel>
					</Dialog>
				</div>
			</div>
		</>
	);
}

export default Main;