import { createIcons, icons } from "lucide";
import { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import '../../../assets/css/tabulator-edit.css';
import { useLoader } from "../../../base-components/Loader";
import Dialog from "../../../base-components/Headless/Dialog";
import Button from "../../../base-components/Button";
import Menu from "../../../base-components/Headless/Menu";
import Lucide from "../../../base-components/Lucide";
import * as xlsx from "xlsx";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import FormLabel from "../../../base-components/Form/FormLabel";

import Litepicker from "../../../base-components/Litepicker";
import FormSelect from "../../../base-components/Form/FormSelect";
export default function Main() {

	const navigate = useNavigate()
	const { showLoader, hideLoader } = useLoader();
	const { groupid } = useParams();
	const tableRef = createRef<HTMLDivElement>();
	const tabulator = useRef<Tabulator>();
	const [from, setFrom] = useState('')
	const [to, setTo] = useState('')
	const [modal, setModal] = useState({ msg: '', title: '', type: '' });
	const [basicModalPreview, setBasicModalPreview] = useState(false)
	const [deleteModalPreview, setDeleteModalPreview] = useState(false)
	const [totPages, setTotPages] = useState(0)
	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");
	const [memberId, setMemberId] = useState('');
	const [mode, setMode] = useState('0');
	const [areaName, setAreaName] = useState<any>()
	const [agentNameS, setAgentNameS] = useState<any>()
	const [areaId, setAreaId] = useState('')
	const [agentIdS, setAgentIdS] = useState('')

	const redrow = () => {
		if (tabulator.current) {
			tabulator.current.setData('/api/collection_approve', { from: from, to: to, agentId: agentIdS, areaId: areaId })
			//console.log('redraw')
		}
	}
		useEffect(() => { }, [memberId])
		const initTabulator = () => {
			if (tableRef.current) {
				// console.log(groupid);
				tabulator.current = new Tabulator(tableRef.current, {
					ajaxFiltering: true,

					ajaxURL: `/api/collection_approve`, paginationMode: "remote", filterMode: "remote", sortMode: "remote", ajaxParams: { from: from, to: to },
					movableColumns: true, ajaxResponse: (_url, p, res) => {
						//console.log('hello');
						setTotPages(res.last_page)
						const page = p.page, size = p.size
						const processedData = res.data.map((row, index) => ({ ...row, srno: ((page - 1) * size) + index + 1, }));

						res.data = processedData
						return res
					},

					pagination: true, paginationSize: 10, paginationSizeSelector: [10, 20, 30, 40], paginationButtonCount: 10,
					reactiveData: true, height: 'auto', layout: "fitDataStretch", placeholder: "No matching records found",
					columns: [
						{ title: "Sr.", field: "srno", print: true, hozAlign: "center", headerHozAlign: 'center', headerSort: false },
						{ title: "Agent Name", field: "agent", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Name", print: true, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{ title: "Member Name", field: "member", print: true, minWidth: 150, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{ title: "Area", field: "area", print: true, minWidth: 150, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{
							title: "Date of Collection", field: "dateOfCollection", headerFilter: "input", minWidth: 200, headerFilterPlaceholder: "Search Date", print: true, hozAlign: "center", headerHozAlign: 'center', validator: "required",
							formatter: (cell) => {
								// Replace \n with <br> to show new lines
								return cell.getValue().replace(/\n/g, '<br>');
							},
							formatterParams: {
								html: true // Ensure that the formatter outputs HTML
							},
						},
						//{ title: "Installment Amount", field: "installmentAmount", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{ title: "Collected Amount", field: "collectedAmount", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{
							title: "Pendiing Installment", field: "pendingInstallment", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true,
							formatter: (cell) => {
								let value = cell.getValue();

								// Convert value to string if it's not already
								if (typeof value !== 'string') {
									value = String(value);
								}

								// Replace \n with <br> to show new lines
								return value.replace(/\n/g, '<br>');
							},
							formatterParams: {
								html: true // Ensure that the formatter outputs HTML
							}
						},
						{ title: "Tot Pending Amt", field: "totPenAmt", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
						//{ title: "Remaining Balance", field: "remainingBalance", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },
						{ title: "Wallet Balance", field: "walletBalance", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },

						//{ title: "Agent", field: "agentName", print: true, minWidth: 180, hozAlign: "center", headerHozAlign: 'center', visible: true },

						{
							title: 'Action', minWidth: 100,
							formatter: () => '<button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Approve</button>',
							cellClick: (e, cell) => {
								const planData = cell.getRow().getData();
								//setBasicModalPreview(true);
								console.log(planData);
								setMemberId(planData.enqueryId);
								setDeleteModalPreview(true);
							},
						},

					],
				});
			}

			tabulator.current?.on("renderComplete", () => { createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", }); });
		};
		// Redraw table onresize
		const reInitOnResizeWindow = () => {
			window.addEventListener("resize", () => {
				if (tabulator.current) {
					tabulator.current.redraw();
					createIcons({ icons, attrs: { "stroke-width": 1.5, }, nameAttr: "data-lucide", });
				}
			});
		};

		const approveCollection = async (id) => {
			try {
				const response = await fetch(`/api/approve-collection/${id}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if (response.ok) {
					const data = await response.json();
					setDeleteModalPreview(false);
					setBasicModalPreview(true);
					redrow();
					console.log(data)
				} else {
					console.log('error')
				}
			} catch (error) {
				console.error('Error approving collection:', error);

			}
		};


		useEffect(() => {
			initTabulator()
			reInitOnResizeWindow()
			setTo('')
			setFrom('')
			getAgentName()
			getAreaName()
		}, [])
		async function getAgentName() {
			try {

				showLoader()
				const f = await fetch('/api/get-agent-name')
				if (f.ok) {
					const r = await f.json()
					if (r.e) alert(r.d)
					else {
						setAgentNameS(r.d)
						//console.log(agentName);
					}
				} else console.error(f.status, f.statusText)
			} catch (e) { console.error(e) } finally { hideLoader() }
		}
		async function getAreaName() {
			try {

				showLoader()
				const f = await fetch('/api/get-area-name')
				if (f.ok) {
					const r = await f.json()
					if (r.e) alert(r.d)
					else {
						setAreaName(r.d)
						//console.log(agentName);
					}
				} else console.error(f.status, f.statusText)
			} catch (e) { console.error(e) } finally { hideLoader() }
		}
		useEffect(() => {
			console.log(mode);
		}, [mode])
		const onPrint = () => { if (tabulator.current) tabulator.current.print(); };
		const onExportPdf = () => {
			if (tabulator.current) {
				const doc = new jsPDF({ orientation: 'portrait', unit: 'px', format: 'a4' })
				doc.autoTable({
					head: [['Sr.', 'AgentName', 'Member Name', 'collection Date', 'collected Amount', 'pending Amount', 'remaining balance']],
					body: tabulator.current.getData().map(row => [row.srno, row.name, row.groupName, row.date, row.disburseAmount, row.installmentAmount, row.duration, row.frequency, row.processingFee, row.agentName]),
				});

				doc.save('Expenses.pdf')
			}
			// tabulator.current.download("pdf", "data.pdf",{ jsPDF,orientation: 'portrait', title: '', autoTable: { styles: { fillColor: [255, 0, 0] } } });
		};
		const onExportCsv = () => { if (tabulator.current) tabulator.current.download("csv", "data.csv"); };
		const onExportJson = () => { if (tabulator.current) tabulator.current.download("json", "data.json"); };
		const onExportHtml = () => { if (tabulator.current) tabulator.current.download("html", "data.html", { style: true, }); };

		const onExportXlsx = () => {
			if (tabulator.current) {
				(window as any).XLSX = xlsx;
				tabulator.current.download("xlsx", "data.xlsx", { sheetName: "Products", });
			}
		};
		return <>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row"><h2 className="mr-auto text-lg font-medium">Collection List </h2>

			</div>
			<div className="grid grid-cols-12 gap-4 gap-y-3">
				<div className="col-span-12 lg:col-span-5">
					<FormLabel htmlFor="modal-datepicker-1">From</FormLabel>
					<Litepicker
						id="modal-datepicker-1"
						value={from}
						onChange={setFrom}
						options={{
							autoApply: false, format: 'DD/MM/YYYY',
							showWeekNumbers: true,
							dropdowns: {
								minYear: 1990,
								maxYear: null,
								months: true,
								years: true,
							},
						}}
					/>
				</div>
				<div className="col-span-12 lg:col-span-5">
					<FormLabel htmlFor="modal-datepicker-2">To</FormLabel>
					<Litepicker
						id="modal-datepicker-2"
						value={to}
						onChange={setTo}
						options={{
							autoApply: false, format: 'DD/MM/YYYY',
							showWeekNumbers: true,
							dropdowns: {
								minYear: 1990,
								maxYear: null,
								months: true,
								years: true,
							},
						}}
					/>
				</div>
				<div className="col-span-12 lg:col-span-2 flex items-end">
					<Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>
				</div>
			</div>
			<div className="grid grid-cols-12 gap-4 gap-y-3">
				<div className="col-span-12 lg:col-span-4">
					<div className="input-form mb-5">
						<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Agent Name</FormLabel>

						<FormSelect id='sCat' onChange={(e) => setAgentIdS(e.currentTarget.value)} name='categoryId' value={agentIdS} aria-label="Default select example">
							<option value='0'>Select Agent</option>
							{(agentNameS != undefined && agentNameS && agentNameS.length > 0) ? agentNameS.map((cat: any) => (
								<option value={cat.id} key={cat.id}>{cat.name}</option>
							)) : <option>Nothing to show</option>}
						</FormSelect>
					</div>
				</div>
				<div className="col-span-12 lg:col-span-2 flex items-end mb-5">
					<Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>
				</div>
				<div className="col-span-12 lg:col-span-4">
					<div className="input-form mb-5">
						<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Area Name</FormLabel>

						<FormSelect id='sCat' onChange={(e) => setAreaId(e.currentTarget.value)} name='categoryId' value={areaId} aria-label="Default select example">
							<option value='0'>Select Area</option>
							{(areaName != undefined && areaName && areaName.length > 0) ? areaName.map((cat: any) => (
								<option value={cat.id} key={cat.id}>{cat.name}</option>
							)) : <option>Nothing to show</option>}
						</FormSelect>
					</div>
				</div>
				<div className="col-span-12 lg:col-span-2 flex items-end mb-5">
					<Button className="mr-2 w-full" variant="primary" onClick={() => redrow()}>Search</Button>

				</div>
			</div>
			<div className="p-5 mt-5 intro-y box">
				<div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
					<div className="xl:flex sm:mr-auto">

						<Button id="tabulator-print" className="w-1/2 mr-2 sm:w-auto" variant="outline-secondary" onClick={onPrint}><Lucide icon="Printer" className="w-4 h-4 mr-2" />Print</Button>
						<Menu className="w-1/2 sm:w-auto">
							<Menu.Button as={Button} variant="outline-secondary" className="w-full sm:w-auto">
								<Lucide icon="FileText" className="w-4 h-4 mr-2" /> Export
								<Lucide icon="ChevronDown" className="w-4 h-4 ml-auto sm:ml-2" />
							</Menu.Button>
							<Menu.Items className="w-40">
								<Menu.Item onClick={onExportPdf}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export PDF</Menu.Item>
								<Menu.Item onClick={onExportCsv}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export CSV</Menu.Item>
								<Menu.Item onClick={onExportJson}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export JSON</Menu.Item>
								<Menu.Item onClick={onExportXlsx}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export XLSX</Menu.Item>
								<Menu.Item onClick={onExportHtml}><Lucide icon="FileText" className="w-4 h-4 mr-2" />Export HTML</Menu.Item>
							</Menu.Items>
						</Menu>
					</div>
					<Button onClick={() => {

						if (tabulator.current) {
							tabulator.current.setData()
							setTo('')
							setFrom('')
							setAgentIdS('')
							setAreaId('')
						}

					}}><Lucide icon="RefreshCcw" className="w-4 h-4 mr-3" /></Button>
				</div>
				<div className="overflow-x-auto scrollbar-hidden">
					<div id="tabulator" ref={tableRef} className="mt-5"></div>
				</div>
				<div className='flex w-full'>
					<span className='ml-auto mr-10'><b>Total Pages: </b>{totPages}</span>
				</div>
			</div>
			<Dialog open={deleteModalPreview} onClose={() => {
				setDeleteModalPreview(false);
			}}

			>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="CheckIcon" className="w-16 h-16 mx-auto mt-3 text-success" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">
							Do you really want to Approve these records? <br />
							This process cannot be undone.
						</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button type="button" variant="outline-secondary" onClick={() => {
							setDeleteModalPreview(false);
						}}
							className="w-24 mr-1"
						>
							Cancel
						</Button>
						<Button type="button" variant="success" className="w-24" onClick={() => {
							approveCollection(memberId);

						}} >
							Approve
						</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={basicModalPreview} onClose={() => { setBasicModalPreview(false); }} >

				<Dialog.Panel className="px-5 py-10">
					<div className="text-center">
						<div className="mb-5">Collection Approved successfully..!</div>
						<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
							setBasicModalPreview(false);
						}}>Ok</Button>
					</div>
				</Dialog.Panel>
			</Dialog>

		</>
	}
