import React, { useState, useEffect } from 'react';



const BankDetails = ({ id }) => {
    const [accountInfo, setAccountInfo] = useState<any>(null);
    async function getGuranter() {
        try {

            //showLoader()
            // console.log({ id });
            const f = await fetch(`/api/Bank-details/${id}`)
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    //  setExpenseName(r.d)
                    setAccountInfo(r.d)
                    //console.log(r.d);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { }
    }
    useEffect(() => { getGuranter(); }, [])
    //const accountInfo = {
    //    name: "John Doe",
    //    bankName: "ABC Bank",
    //    branch: "Main Branch",
    //    accountNumber: "1234567890",
    //    ifsc: "ABCD0123456",
    //    upi: "john.doe@upi",
    //    description: "This is a description of the bank account and its details.",
    //    passbookPhoto: "https://imaginationhunt.com/wp-content/uploads/2021/02/How-to-Get-SBI-Passbook-Online.jpg", // Replace with actual image path
    //};

    return (<>{accountInfo ?
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                {/* Name */}
                <div>
                    <label className="block text-gray-700 font-bold mb-2">Name</label>
                    <p className="border border-gray-300 p-2 rounded">{accountInfo.name}</p>
                </div>

                {/* Bank Name */}
                <div>
                    <label className="block text-gray-700 font-bold mb-2">Bank Name</label>
                    <p className="border border-gray-300 p-2 rounded">{accountInfo.bankName}</p>
                </div>

                {/* Branch */}
                <div>
                    <label className="block text-gray-700 font-bold mb-2">Branch</label>
                    <p className="border border-gray-300 p-2 rounded">{accountInfo.branch}</p>
                </div>

                {/* Account Number */}
                <div>
                    <label className="block text-gray-700 font-bold mb-2">Account Number</label>
                    <p className="border border-gray-300 p-2 rounded">{accountInfo.accountNo}</p>
                </div>

                {/* IFSC */}
                <div>
                    <label className="block text-gray-700 font-bold mb-2">IFSC Code</label>
                    <p className="border border-gray-300 p-2 rounded">{accountInfo.ifscCode}</p>
                </div>

                {/* UPI */}
                <div>
                    <label className="block text-gray-700 font-bold mb-2">UPI</label>
                    <p className="border border-gray-300 p-2 rounded">{accountInfo.upi}</p>
                </div>

                {/* Description */}
                <div className="md:col-span-2">
                    <label className="block text-gray-700 font-bold mb-2">Description</label>
                    <p className="border border-gray-300 p-2 rounded">{accountInfo.description}</p>
                </div>

               
                <div className="md:col-span-2">
                    <label className="block text-gray-700 font-bold mb-2">Passbook Photo</label>
                    <img
                        src={accountInfo.passbookPhoto}
                        alt="Passbook Photo"
                        className="border border-gray-300 p-2 rounded max-w-full"
                    />
                </div>

            </div>
        </div> : <p>Data Not FillUp</p>}</>
    );
};


export default BankDetails;