import { useEffect, useRef, useState } from "react"
import DataTable from "react-data-table-component"
import Button from "../../../base-components/Button"
import { FormSelect } from "../../../base-components/Form"
import FormInput from "../../../base-components/Form/FormInput"
import { Dialog } from "../../../base-components/Headless"
import LoadingIcon from "../../../base-components/LoadingIcon"
import Lucide from "../../../base-components/Lucide"
import { useLoader } from "../../../base-components/Loader"

type RawMaterial = { id: number, categoryId: string, rawmaterial: string, }
type DtValues = { loading: boolean, totalRows: number, sortDirection: string, column: number, perPage: number, page: number, }

function Main() {
	const { showLoader, hideLoader } = useLoader()
	const [data, setData] = useState([])
	const [dtValues, setDtValues] = useState<DtValues>({ loading: true, totalRows: 10, sortDirection: 'asc', column: 1, page: 1, perPage: 10 })

	const columns = [
		{ name: 'ID', selector: (row: any) => row.id, sortable: true, },
		{ name: 'Raw Material', selector: (row: any) => row.rm, sortable: true, },
		{ name: 'Category', selector: (row: any) => row.category, sortable: true, },
		{ name: 'Actions', selector: (row: any) => btn(row.id), sortable: false },
	]

	const btn = (id: any) => <>
		<Button rounded className="mx-2" variant="outline-success" onClick={() => handleEditClick(id)}>Edit</Button>{' '}
		<Button rounded className="mx-2" variant="outline-danger" onClick={(e: any) => handleDeleteClick(e, id)}>Delete</Button>
	</>

	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
	const deleteButtonRef = useRef(null)
	const [updateConfirmationModal, setUpdateConfirmationModal] = useState(false)
	const updateButtonRef = useRef(null)

	const [btnText, setBtnText] = useState("Add")
	const [msg, setMsg] = useState("")

	const [categoryList, setCategoryList] = useState<any[]>()
	const [rm, setRM] = useState<RawMaterial | null>({ id: 0, categoryId: '', rawmaterial: '' })

	useEffect(() => {
		getCategories()
		fetchUsers()
	}, [])

	const handleSubmit = () => {
		if (rm == null || rm?.categoryId == null || rm.categoryId == '' || rm?.rawmaterial == null || rm.rawmaterial == '') { alert('Enter value & try again'); return; }
		if (btnText == "Add") {
			const m = `Add category ${findTextByValue(rm?.categoryId)} & Raw material ${rm?.rawmaterial}?`
			setMsg(m)
			addRawMaterial()
		} else { }
		setUpdateConfirmationModal(true)
	}
	const handleCancel = () => {
		setRM(null)
		setBtnText("Add")
	}

	const addUpdateCategory = () => {
		(btnText == "Add") ? addRawMaterial() : updateRawMaterial();

		setRM(null)
		setUpdateConfirmationModal(false);
	}

	async function addRawMaterial() {
		try {
			showLoader()
			const f = await fetch('/api/add_raw_material', { method: 'POST', body: JSON.stringify(rm), headers: { 'Content-Type': 'application/json', }, })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					if (data != undefined) {//Add locally
						const t = (categoryList: any) => [...categoryList, rm] as any
						setData(t);
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) }
		finally { hideLoader() }
	}

	async function updateRawMaterial() {
		try {
			showLoader()
			const f = await fetch('/api/update_raw_material', { method: 'PUT', body: JSON.stringify(rm), headers: { 'Content-Type': 'application/json', }, })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					setBtnText("Add")
					if (data != undefined) {//Update locally
						const o = data.find((item: any) => item.id === rm?.id) as any
						const o1 = o
						const cat = findTextByValue(rm?.categoryId + '')
						o1.category = cat
						o1.rm = rm?.rawmaterial
						Object.assign(o, o1)
					}
				}
			}
		}
		catch (e) { console.error(e) }
		finally { hideLoader() }
	}
	async function deleteCategory() {
		try {
			showLoader()
			const f = await fetch('/api/delete_raw_material', { method: 'DELETE', body: JSON.stringify({ id: rm?.id }), headers: { 'Content-Type': 'application/json', }, })
			if (f.ok) {
				const j = await f.json()
				hideLoader()
				if (j.e) { }
				else {
					setBtnText("Add")
					if (data != undefined) {//Delete locally
						const o = data.find((item: any) => item.id === rm?.id) as any
						const updatedCategoryList = data.filter((category) => category !== o)
						setData(updatedCategoryList)

						setRM(null)
						setDeleteConfirmationModal(false);
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	function handleEditClick(id: number) {
		const row = data.find((x: any) => x.id == id) as any
		const m = `Edit category ${row.category} to ${findTextByValue(rm?.categoryId)} & Raw material ${row.rm} to ${rm?.rawmaterial}?`
		setMsg(m)
		const catId = findValueByText(row.category)
		const rm1 = { id: id, rawmaterial: row.rm, categoryId: catId }
		setRM(rm1)//setId(id)
		setBtnText("Update")
	}
	const findValueByText = (text: any) => {
		const selectElement = document.getElementById('sCat') as any
		if (selectElement) {
			for (let i = 0; i < selectElement.options.length; i++) {
				const option = selectElement.options[i];
				if (option.text === text) return option.value;
			}
		}
		return null;
	};
	const findTextByValue = (value: any) => {
		const selectElement = document.getElementById('sCat') as any
		if (selectElement) {
			for (let i = 0; i < selectElement.options.length; i++) {
				const option = selectElement.options[i];
				if (option.value === value) return option.text;
			}
		}
		return null;
	};

	function handleDeleteClick(e: any, id: any) {
		e.preventDefault();
		const row = data.find((x: any) => x.id == id) as any
		setRM({ id: id, rawmaterial: row.rm, categoryId: row.category })
		setDeleteConfirmationModal(true)
	}

	async function getCategories() {
		try {
			showLoader()
			const f = await fetch('/api/categories')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else setCategoryList(r.d)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	const handelChange = (e: any) => {
		const fieldName = e.target.getAttribute('name');
		const fieldValue = e.target.value;

		const newFormData = { ...rm } as any;
		newFormData[fieldName] = fieldValue;

		setRM(newFormData)
	}

	const fetchUsers = async () => {
		try {
			showLoader()
			dtValues.loading = true
			const params = `page=${dtValues.page}&per_page=${dtValues.perPage}&column=${dtValues.column}&sortDirection=${dtValues.sortDirection}`
			const response = await fetch(`/api/raw_materials?` + params)
			if (response.ok) {
				const r = await response.json()
				hideLoader()
				setData(r.data);
				dtValues.totalRows = r.total
				dtValues.loading = false
			} else console.error(response.status + ' ' + response.statusText)
		} catch (e) { console.error(e) }
		finally { hideLoader() }
	}

	const handlePageChange = async (page: number) => {
		dtValues.page = page
		setDtValues(dtValues)
		fetchUsers()
	}

	const handlePerRowsChange = (newPerPage: any, page: any) => {
		dtValues.perPage = newPerPage
		dtValues.page = page
		fetchUsers()
	}

	const handleSort = (column: any, sortDirection: any) => {
		dtValues.column = column
		dtValues.sortDirection = sortDirection
		fetchUsers()
	}

	return <>
		<div className="mt-5 intro-y box">
			<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60">
				<h2 className="mr-auto text-base font-medium">Raw Materials</h2>
			</div>
			<div className="p-5">
				<div className='flex justify-center align-center gap-2'>
					<FormSelect id='sCat' onChange={handelChange} name='categoryId' value={rm?.categoryId ?? 0} aria-label="Default select example">
						<option value='0'>Select Category</option>
						{(categoryList != undefined && categoryList && categoryList.length > 0) ? categoryList.map((cat: any) => (
							<option value={cat.id} key={cat.id}>{cat.category}</option>
						)) : <option>Nothing to show</option>}
					</FormSelect>
					<FormInput onChange={handelChange} name='rawmaterial' value={rm?.rawmaterial ?? ''} className="col-span-3" placeholder="Raw Material" required />
				</div>
				<span className='flex justify-center items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0'>
					<Button variant="outline-primary" onClick={handleSubmit} className="mx-2 my-3">{btnText}</Button>
					<Button variant="outline-warning" onClick={handleCancel} className="mx-2 my-3">Cancel</Button>
				</span>
				<div className="overflow-x-auto">
					<DataTable title="Raw Material" striped responsive columns={columns} data={data} progressPending={dtValues.loading} pagination paginationServer
						paginationTotalRows={dtValues.totalRows} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange}
						onSort={handleSort} //onColumnOrderChange={(col: any) =>{}}
						progressComponent={<LoadingIcon icon="rings" className="w-200 h-200" />}
						fixedHeader={false} highlightOnHover={true}
						paginationRowsPerPageOptions={[10, 100, 1000, 10000, 100000]} />
				</div>
			</div>
		</div>
		<Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
					<div className="mt-5 text-3xl">Are you sure?</div>
					<div className="mt-2 text-slate-500">{msg}<br />This process cannot be undone.</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
					<Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteCategory} >Delete</Button>
				</div>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={updateConfirmationModal} initialFocus={updateButtonRef} onClose={() => { setUpdateConfirmationModal(false); }}>
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
					<div className="mt-5 text-3xl">Are you sure?</div>
					<div className="mt-2 text-slate-500">{msg}<br />This process cannot be undone.</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button variant="outline-secondary" type="button" onClick={() => { setUpdateConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
					<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef} onClick={addUpdateCategory} >{btnText}</Button>
				</div>
			</Dialog.Panel>
		</Dialog>
	</>
}

export default Main;