import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Litepicker from "../../../base-components/Litepicker";
import { FormLabel, FormInput, FormTextarea, FormInline, } from "../../../base-components/Form";
import Notification from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import Toastify from "toastify-js";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import Button from "../../../base-components/Button";
import { Dialog } from "../../../base-components/Headless";
import { FormSelect } from "../../../base-components/Form";
import { useForm } from "react-hook-form";
import './client.css'
import { useLoader } from "../../../base-components/Loader";

function Main() {
    const { showLoader, hideLoader } = useLoader();
    const navigate = useNavigate();
    const { planid } = useParams();
    const [freqName, setFreqName] = useState<any>()
    const eventId = useSelector((state: RootState) => state.eventId.eventId);

    const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

    const [dateRange, setDateRange] = useState(dateRange1);
    const [invalidAlert, setInvalidAlert] = useState(false);
    const [freqType, setFreqType] = useState('');
    const [interest, setInterest] = useState(0);
    const initClient = { amt: 0, installation: 0, name: '', duration: 0, processingfee: 0, Frequency:0 }
    const [planCreation, setPlanCraetion] = useState(initClient)

    //const [clientData, setClientData] = useState([]);
    const [records, setRecords] = useState([])
    const [showList, setShowList] = useState(true);
    const [btnText, setBtnText] = useState('Add Plan');

    const schema = yup.object().shape({
        amt: yup.number().required('Amount is required'),
        installation: yup.number(),
        //interest: yup.number(),
        name: yup.string().trim().required().min(1),
        Frequency: yup.number().required('Frequency is required'),
        
        //	.matches(/^[1-9]\d*$/, { excludeEmptyString: true, message: 'Caste is required', }),
        //address: yup.string().required().min(4),
        //eventAddress: yup.string().required().min(4),
        duration: yup.number().required('Duration is required'),
        processingfee: yup.number().required('Processing Fee is required'),
        //installation: yup.string().min(10).max(10)//.required()
        //	.matches(/\d{10}/, { excludeEmptyString: true, message: 'Invalid phone number format', }),
    }).required();

    const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
        criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
        values: planCreation, reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    })

    const calInterest = () => {
        if (planCreation.installation > 0 && planCreation.duration > 0 && planCreation.amt > 0) {
            var result = (planCreation.installation * planCreation.duration) - planCreation.amt;
            var inter = (result / (planCreation.amt * planCreation.duration)) * 100;
            //planCreation.interest = result;
            setInterest(Number((inter ?? 0).toFixed(2)))
        } else setInterest(0)
    }
    useEffect(() => { calInterest() }, [planCreation])

    const getdata = async () => {
        if (planid) {
            try {
                const f = await fetch(`/api/plan-creation/${planid}`)
                if (f.ok) {
                    const d = await f.json();
                    setFreqType(d.i.frequency);
                    setPlanCraetion({ ...planCreation, amt: d.i.amount, duration: d.i.duration, installation: d.i.installation, name: d.i.name, processingfee: d.i.processingFee })
                    setBtnText('Update Plan')
                } else console.error(f.status, f.statusText)
            } catch (e) { } finally {
                hideLoader()
            }
        }
    }

    useEffect(() => {
        getdata();
    }, [])

    const handleChange = (e) => {
        const name = e.target.value
        setPlanCraetion((recentData) => ({ ...recentData, [e.target.name]: name }))

 
    }
    function getFormValuesAsJson(form: HTMLFormElement): any {
        const formData = new FormData(form);
        const values: any = {};

        for (const entry of formData.entries()) {
            const [name, value] = entry;
            if (name === '') continue;
            if (Object.prototype.hasOwnProperty.call(values, name)) {
                if (typeof values[name] === 'string') values[name] = [values[name], value];
                else values[name].push(value);
            } else values[name] = value;
        }
        return values;
    }

    const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            console.log(planid)

            const result = await trigger()
            if (result) {
                showLoader()
                setPlanCraetion(getFormValuesAsJson(event.target))

                const d = JSON.stringify({ ...planCreation, id: planid, interest, frequency:freqType })
                console.log(d)

                const f = await fetch('/api/plan-creation', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
                if (f.ok) {
                    const data = await f.json()
                    setInvalidAlert(true);

                } else console.error(f.status, f.statusText)
            }
            //else setInvalidAlert(true)
        } catch (error) { console.error(error) } finally { hideLoader() }
    }

    async function getCategories() {
        try {

            showLoader()
            const f = await fetch('/api/freq-list')
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    setFreqName(r.d)
                    console.log(freqName);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { hideLoader() }
    }
    useEffect(() => {
        getCategories()
    }, [])


    return (
        <>
            
            <div className="flex items-center mt-8 intro-y">
                <h1 className="mr-auto text-lg font-medium">Plan Creation</h1>
                <div className="flex w-full mt-4 sm:w-auto sm:mt-0">
                    <Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/plan-list')} >Plan List</Button>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="col-span-12 intro-y lg:col-span-12">
                    <div className="p-5 intro-y box">
                        <form className="validate-form" onSubmit={onSubmit2}>
                            <div className="input-form mb-5">
                                <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Plan Name
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                </FormLabel>
                                <FormInput {...register("name")} name="name" value={planCreation.name} placeholder="Enter Plan Name"
                                    onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />

                                {errors.name && (<div className="mt-2 text-danger">{typeof errors.name.message === "string" && errors.name.message}</div>)}
                                
                            </div>
                            <div className="input-form mb-5">
                                <FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Frequency Type
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                </FormLabel>
                                {/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
                                {/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
                                <FormSelect {...register("Frequency")} onChange={(e) => setFreqType(e.currentTarget.value)} name='categoryId' value={freqType} aria-label="Default select example">
                                    <option value='0'>Select Type</option>
                                    {(freqName != undefined && freqName && freqName.length > 0) ? freqName.map((cat: any) => (
                                        <option value={cat.id} key={cat.id}>{cat.name}</option>
                                    )) : <option>Nothing to show</option>}
                                </FormSelect>
                            </div>
                            <div className="input-form mb-5">
                                <FormLabel className="flex flex-col w-full sm:flex-row"> Plan Amount
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                </FormLabel>
                                <FormInput {...register("amt")} value={planCreation.amt} placeholder="Enter Amount"
                                    onChange={handleChange} className={clsx({ "border-danger": errors.amt })} autoComplete='off' />

                                {errors.amt && (<div className="mt-2 text-danger">{typeof errors.amt.message === "string" && errors.amt.message}</div>)}
                               
                            </div>
                            <div className="input-form mb-5">
                                <FormLabel className="flex flex-col w-full sm:flex-row"> Plan Installment
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                </FormLabel>
                                <FormInput {...register("installation")} value={planCreation.installation} placeholder="Enter installation"
                                    onChange={handleChange} className={clsx({ "border-danger": errors.installation })} autoComplete='off' />

                                {errors.installation && (<div className="mt-2 text-danger">{typeof errors.installation.message === "string" && errors.installation.message}</div>)}
                                
                            </div>
                            <div className="input-form mb-5">
                                <FormLabel className="flex flex-col w-full sm:flex-row"> Processing Fee
                                    {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
                                </FormLabel>
                                <FormInput {...register("processingfee")} value={planCreation.processingfee} placeholder="Enter installation"
                                    onChange={handleChange} className={clsx({ "border-danger": errors.processingfee })} autoComplete='off' />

                                {errors.processingfee && (<div className="mt-2 text-danger">{typeof errors.processingfee.message === "string" && errors.processingfee.message}</div>)}
                                
                            </div>
                           
                            <div className="col-span-12 intro-y lg:col-span-6">
                                <div className="input-form">
                                    <FormLabel className="flex flex-col w-full sm:flex-row">Duration In Weeks
                                        {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
                                        {/*	Required at least 5 character*/}
                                        {/*</span>*/}
                                    </FormLabel>
                                    <FormInput {...register("duration")} type="text" value={planCreation.duration}
                                        onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.duration })}
                                        placeholder="Enter Duration" />
                                    {errors.duration && (<div className="mt-2 text-danger"> {typeof errors.duration.message === "string" && errors.duration.message} </div>)}
                                </div>
                            </div>
                            <div className="col-span-12 intro-y lg:col-span-6">
                                <div className="input-form">
                                    <FormLabel className="flex flex-col w-full sm:flex-row">Interest
                                        {/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
                                        {/*	Required at least 5 character*/}
                                        {/*</span>*/}
                                    </FormLabel>
                                    {/*{...register("interest")}*/}
                                    <FormInput  type="text" value={interest}
                                        onChange={handleChange} maxLength={200}
                                        placeholder="Enter Interest" />
                                </div>
                            </div>
                           
                            <Button variant="primary" type="submit" className="m-2">{btnText}</Button>
                        </form>
                    </div>
                   
                    <Dialog id='failed-notification-content' staticBackdrop open={invalidAlert} onClose={() => { setInvalidAlert(false); }} >
                        <Dialog.Panel className="px-5 py-10">
                            <div className="text-center">
                                <div className="mb-5">{btnText} successfully..!</div>
                                <Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
                                    navigate('/finance/plan-list')
                                    setInvalidAlert(false)
                                }}>Ok</Button>
                            </div>
                        </Dialog.Panel>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

export default Main;