import React, { useEffect, useState } from 'react';


const FullDetailsComponent = ({ id }) => {
    const [guarantors, setGuarantors] = useState<any>(null);
    async function getGuranter() {
        try {

            //showLoader()
           // console.log({ id });
            const f = await fetch(`/api/view-details/${id}`)
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    //  setExpenseName(r.d)
                    setGuarantors(r.d)
                    //console.log(r.d);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { }
    }
    useEffect(() => { getGuranter(); }, [])
    return (<>
        { guarantors?
        <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '8px' }}>
            <h3 className='mr-12'>Full Details</h3>
            <br></br>
            <div className='flex flex-row justify-center'>
                <div className='flex flex-col items-end'>
                    <p><strong>Name:</strong> &nbsp;</p>
                    <p><strong>Mobile:</strong> &nbsp;</p>
                    <p><strong>Address:</strong> &nbsp;</p>
                    <p><strong>Relative Mobile:</strong> &nbsp;</p>
                    <p><strong>Reason:</strong> &nbsp;</p>
                    <p><strong>Return Installment:</strong> &nbsp;</p>
                    <p><strong>Other Loan:</strong> &nbsp;</p>
                    <p><strong>CIBIL Score:</strong> &nbsp;</p>
                    <p><strong>Family Income:</strong> &nbsp;</p>
                    <p><strong>Yearly Income:</strong> &nbsp;</p>
                    <p><strong>Own House:</strong> &nbsp;</p>
                    <p><strong>Own Business:</strong> &nbsp;</p>
                    <p><strong>Total Capital:</strong> &nbsp;</p>
                </div>
                <div className='flex flex-col items-start'>
                    <p>&nbsp; {guarantors.name}</p>
                    <p>&nbsp; {guarantors.mobile}</p>
                    <p>&nbsp; {guarantors.address}</p>
                    <p>&nbsp; {guarantors.relativeMobile}</p>
                    <p>&nbsp; {guarantors.reason}</p>
                    <p>&nbsp; {guarantors.returnInstallment}</p>
                    <p>&nbsp; {guarantors.otherLoan}</p>
                    <p>&nbsp; {guarantors.cibilScore}</p>
                    <p>&nbsp; {guarantors.familyIncome}</p>
                    <p>&nbsp; {guarantors.yearlyIncome}</p>
                        <p>&nbsp; {guarantors.ownHouse ? 'yes' : 'no'}</p>
                        <p>&nbsp; {guarantors.ownBusiness ? 'yes' : 'no'}</p>
                    <p>&nbsp; {guarantors.totalCapital}</p>
                </div>
            </div>
            </div> : <p>data not Fillup</p>}
        
    </>
    );
};

export default FullDetailsComponent;
