import React, { useEffect, useState } from 'react';




const DocumentView = ({ id }) => {
    const [document, setDocument] = useState<any>(null);
    async function getGuranter() {
        try {

            //showLoader()
             console.log({ id });
            const f = await fetch(`/api/view-document/${id}`)
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    //  setExpenseName(r.d)
                    setDocument(r.d)
                    console.log(r.d);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally { }
    }
    useEffect(() => { getGuranter(); }, [])

    const [isPhotoVisible, setIsPhotoVisible] = useState(false);
    const [isAadhaarVisible, setIsAadhaarVisible] = useState(false);
    const [isSignatureVisible, setIsSignatureVisible] = useState(false);
    const [isPanVisible, setIsPanVisible] = useState(false);

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
            <div>
                <h3>Member Photo</h3>
                {!isPhotoVisible ? (
                    <button onClick={() => setIsPhotoVisible(true)}>View Photo</button>
                ) : (
                        <img src={document.memberPhoto} alt="Member Photo" style={{ width: '100%' }} />
                )}
            </div>

            <div>
                <h3>Signature</h3>
                {!isSignatureVisible ? (
                    <button onClick={() => setIsSignatureVisible(true)}>View Signature</button>
                ) : (
                        <img src={document.memberSign} alt="Signature" style={{ width: '100%' }} />
                )}
            </div>

            <div>
                <h3>Aadhaar</h3>
                {!isAadhaarVisible ? (
                    <button onClick={() => setIsAadhaarVisible(true)}>View Aadhaar</button>
                ) : (
                    <>
                            <img src={document.adharPhoto} alt="Aadhaar" style={{ width: '100%' }} />
                        <p>Aadhaar Number: {document.adharNo}</p>
                    </>
                )}
            </div>

            <div>
                <h3>PAN</h3>
                {!isPanVisible ? (
                    <button onClick={() => setIsPanVisible(true)}>View PAN</button>
                ) : (
                    <>
                            <img src={document.panPhoto} alt="PAN" style={{ width: '100%' }} />
                        <p>PAN Number: {document.panNo}</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default DocumentView;
