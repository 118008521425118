import React, { useEffect, useState } from 'react';


const GuarantorView = ({ id }) => {
    const [guarantors, setGuarantors] = useState<any>(null);
    async function getGuranter() {
        try {

            //showLoader()
           // console.log({id});
            const f = await fetch(`/api/view-guarantor/${id}`)
            if (f.ok) {
                const r = await f.json()
                if (r.e) alert(r.d)
                else {
                    //  setExpenseName(r.d)
                   if(r.d.length>0) setGuarantors(r.d)
                  //  console.log(r.d);
                }
            } else console.error(f.status, f.statusText)
        } catch (e) { console.error(e) } finally {  }
    }
    useEffect(() => { getGuranter(); },[])
   
    return (
        //<div>
        //    <h3>Guarantors</h3>
        //    {guarantors.map((guarantor, index) => (
        //        <Guarantor key={index} {...guarantor} />
        //    ))}
        //</div>
        guarantors && guarantors.length>0? <div style = {{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }
}>
            {guarantors.map((guarantor, index) => (<div key={index}>
                <h4>{guarantor.gName}</h4>
                <p><strong>Aadhaar Number:</strong> {guarantor.adharNo}</p>
                <p><strong>PAN Number:</strong> {guarantor.panNo}</p>
                <p><strong>Relation:</strong> {guarantor.relation}</p>
                <p><strong>Address:</strong> {guarantor.address}</p>
                <p><strong>Mobile:</strong> {guarantor.mobile}</p>
            </div> )
            ) }
        </div>:<p>Data is Not Fillup</p>
    );
};

export default GuarantorView;