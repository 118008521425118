import { useEffect, useRef, useState } from "react";
import Button from "../../../base-components/Button";
import FormInput from "../../../base-components/Form/FormInput";
import { Dialog } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

function Main() {
	const { showLoader, hideLoader } = useLoader();

	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
	const deleteButtonRef = useRef(null);
	const [updateConfirmationModal, setUpdateConfirmationModal] = useState(false);
	const updateButtonRef = useRef(null);

	const [successModalPreview, setSuccessModalPreview] = useState(false);

	const [id, setId] = useState(0);
	const [btnText, setBtnText] = useState("Add");
	const [category, setCategory] = useState(null);
	const [oldCategory, setOldCategory] = useState(null);
	const [categoryList, setCategoryList] = useState<any[]>();

	useEffect(() => { getCategories() }, [])

	const handleChange = ({ target }: any) => { setCategory(target.value) }

	const handleSubmit = () => {
		if (category == null || category == '') { alert('Enter value & try again'); return; }
		setUpdateConfirmationModal(true)
	}
	const handleCancel = () => {
		setCategory(null)
		setBtnText("Add")
	}

	const addUpdateCategory = () => {
		(btnText == "Add") ? insertCategory() : updateCategory();

		setCategory(null)
		setUpdateConfirmationModal(false);
	}

	async function insertCategory() {
		try {
			showLoader()
			const f = await fetch('/api/add_category', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ category: category }), })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					if (categoryList != undefined) {
						const maxid = categoryList.reduce((maxId, obj: any) => (obj.id > maxId ? obj.id : maxId), 0);
						const newObject = { id: maxid + 1, category: category }
						//Add locally
						const t = categoryList => [...categoryList, newObject] as any
						setCategoryList(t);
						setSuccessModalPreview(true);
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function updateCategory() {
		try {
			showLoader()
			const f = await fetch('/api/update_category', { method: 'PUT', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ catid: id, category: category }), })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					setBtnText("Add")
					if (categoryList != undefined) {//Update locally
						const o = categoryList.find(item => item.id === id)
						const o1 = o
						o1.category = category
						Object.assign(o, o1)
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	async function deleteCategory() {
		try {
			showLoader()
			const f = await fetch('/api/delete_category', { method: 'DELETE', headers: { 'Content-Type': 'application/json', }, body: id + '', })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					setBtnText("Add")
					if (categoryList != undefined) {
						//Delete locally
						const o = categoryList.find((item: any) => item.id === id) as any
						//const updatedCategoryList = categoryList.filter((_, index) => index != tid);
						const updatedCategoryList = categoryList.filter(category => category !== o)
						setCategoryList(updatedCategoryList)

						setCategory(null)
						setDeleteConfirmationModal(false);
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	function handleEditClick(cat: any) {
		setOldCategory(cat.category)
		setCategory(cat.category)
		setId(cat.id)
		setBtnText("Update")
	}

	function handleDeleteClick(e: any, cat: any) {
		e.preventDefault();
		setCategory(cat.category)
		setId(cat.id)
		setDeleteConfirmationModal(true);
	}

	async function getCategories() {
		try {
			showLoader()
			var v = await fetch('/api/categories')
			if (v.ok) {
				var j = await v.json();
				if (j.e) { console.error(j) }
				else { setCategoryList(j.d) }
			} else console.error(v.status, v.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	return <>
		<div className="mt-5 intro-y box">
			<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60">
				<h2 className="mr-auto text-base font-medium">Material Category</h2>
			</div>
			<div className="p-5">
				<FormInput type="text" value={category ?? ''} className="col-span-3" placeholder="Catrgory" onChange={handleChange} required />
				<span className='flex justify-center items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0'>
					<Button variant="outline-primary" onClick={handleSubmit} className="mx-2 my-3">{btnText}</Button>
					<Button variant="outline-warning" onClick={handleCancel} className="mx-2 my-3">Cancel</Button>
				</span>
				<Button onClick={getCategories} className='flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0'>Refresh Categories</Button>
				<div className="overflow-x-auto">
					<table className="w-full text-left">
						<thead><tr><th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">Category</th></tr></thead>
						<tbody>{(categoryList == undefined || categoryList.length == 0) ? <tr><td className="flex px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t"><b>Loading...</b></td></tr> :
							categoryList.map(cat => (
								<tr key={cat.id}>
									<td className="flex px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
										{cat.category}
										<span className='flex justify-end items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0'>
											<Button rounded className="mx-2" variant="outline-success" onClick={() => handleEditClick(cat)}>Edit</Button>{' '}
											<Button rounded className="mx-2" variant="outline-danger" onClick={(e: any) => handleDeleteClick(e, cat)}>Delete</Button>
										</span>
									</td>
								</tr>
							))}</tbody>
					</table>
				</div>
			</div>
		</div>
		<Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
					<div className="mt-5 text-3xl">Are you sure?</div>
					<div className="mt-2 text-slate-500">
						Delete category {category}? <br />
						This process cannot be undone.
					</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
					<Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteCategory} >Delete</Button>
				</div>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={updateConfirmationModal} initialFocus={updateButtonRef} onClose={() => { setUpdateConfirmationModal(false); }}>
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
					<div className="mt-5 text-3xl">Are you sure?</div>
					<div className="mt-2 text-slate-500">
						{btnText} category {oldCategory} &gt; {category}? <br />
						This process cannot be undone.
					</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button variant="outline-secondary" type="button" onClick={() => { setUpdateConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
					<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef} onClick={addUpdateCategory} >{btnText}</Button>
				</div>
			</Dialog.Panel>
		</Dialog>
		<Dialog open={successModalPreview} onClose={() => { setSuccessModalPreview(false); }}>
			<Dialog.Panel>
				<div className="p-5 text-center">
					<Lucide icon="CheckCircle" className="w-16 h-16 mx-auto mt-3 text-success" />
					<div className="mt-5 text-3xl">Good job!</div>
					<div className="mt-2 text-slate-500">You clicked the button!</div>
				</div>
				<div className="px-5 pb-8 text-center">
					<Button type="button" variant="primary" onClick={() => { setSuccessModalPreview(false); }} className="w-24">Ok</Button>
				</div>
			</Dialog.Panel>
		</Dialog>
	</>
}

export default Main