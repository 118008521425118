import { useEffect, useRef, useState } from "react";
import Button from "../../../base-components/Button";
import FormInput from "../../../base-components/Form/FormInput";
import { Dialog } from "../../../base-components/Headless";
import { useLoader } from "../../../base-components/Loader";
import Lucide from "../../../base-components/Lucide";

interface Process {
	id: number,
	process: string
}
function Main() {
	const { showLoader, hideLoader } = useLoader();

	const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
	const deleteButtonRef = useRef(null);
	const [updateConfirmationModal, setUpdateConfirmationModal] = useState(false);
	const updateButtonRef = useRef(null);

	const [process, setProcess] = useState<Process>({ id: 0, process: '' });
	const [btnText, setBtnText] = useState("Add");
	const [oldProcess, setOldProcess] = useState('');
	const [processList, setProcessList] = useState<any[]>();

	useEffect(() => { getProcesses() }, [])

	const handleChange = ({ target }: any) => setProcess({ id: process.id, process: target.value })

	const handleSubmit = () => {
		if (process == null || process.process == '') { alert('Enter value & try again'); return; }
		setUpdateConfirmationModal(true)
	}
	const handleCancel = () => {
		setProcess({ id: 0, process: '' })
		setBtnText("Add")
	}

	const addUpdateProcess = () => {
		(btnText == "Add") ? insertProcess() : updateProcess();

		setUpdateConfirmationModal(false);
	}

	async function insertProcess() {
		try {
			showLoader()
			const f = await fetch('/api/add_process', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify({ process: process }), })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					if (processList != undefined) {
						//const maxid = processList.reduce((maxId, obj: any) => (obj.id > maxId ? obj.id : maxId), 0);
						const newObject = { id: j.id, process: process }
						//Add locally
						const t = (processList: any) => [...processList, newObject] as any
						setProcessList(t);
						setProcess({ id: 0, process: '' })
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function updateProcess() {
		try {
			showLoader()
			const f = await fetch('/api/update_process', { method: 'PUT', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(process), })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					setBtnText("Add")

					if (processList != undefined) {//Update locally
						const o = processList.find((item: any) => item.id === process.id) as any
						const o1 = o
						o1.process = process.process
						Object.assign(o, o1)
					}
					setProcess({ id: 0, process: '' })
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	async function deleteprocess() {
		try {
			showLoader()
			const f = await fetch('/api/delete_process', { method: 'DELETE', body: JSON.stringify(process), headers: { 'Content-Type': 'application/json', }, })
			if (f.ok) {
				const j = await f.json()
				if (j.e) { }
				else {
					setBtnText("Add")
					if (processList != undefined) {//Delete locally
						const o = processList.find((item: any) => item.id === process.id) as any
						//const updatedprocessList = processList.filter((_, index) => index != tid);
						const updatedprocessList = processList.filter((process) => process !== o)
						setProcessList(updatedprocessList)

						setProcess({ id: 0, process: '' })
						setDeleteConfirmationModal(false);
					}
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	function handleEditClick(p: any) {
		setProcess(p)
		setOldProcess(p.process)
		setBtnText("Update")
	}

	function handleDeleteClick(e: any, p: any) {
		e.preventDefault();
		setProcess(p)
		setDeleteConfirmationModal(true);
	}

	async function getProcesses() {
		try {
			showLoader()
			const f = await fetch('/api/processes')
			if (f.ok) {
				const j = await f.json()
				setProcessList(j)
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	return (
		<>
			<div className="mt-5 intro-y box">
				<div className="flex flex-col items-center p-5 border-b sm:flex-row border-slate-200/60">
					<h2 className="mr-auto text-base font-medium">Process Master</h2>
				</div>
				<div className="p-5">
					<FormInput type="text" value={process.process ?? ''} className="col-span-3" placeholder="Process" onChange={handleChange} required />
					<span className='flex justify-center items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0'>
						<Button variant="outline-primary" onClick={handleSubmit} className="mx-2 my-3">{btnText}</Button>
						<Button variant="outline-warning" onClick={handleCancel} className="mx-2 my-3">Cancel</Button>
					</span>
					<Button onClick={getProcesses} className='flex items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0'>Refresh Process</Button>
					<div className="overflow-x-auto">
						<table className="w-full text-left">
							<thead><tr><th className="font-medium px-5 py-3 border-b-2 dark:border-darkmode-300 border-l border-r border-t whitespace-nowrap">process</th></tr></thead>
							<tbody>{(processList == undefined || processList.length == 0) ? <tr><td className="flex px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t"><b>Loading...</b></td></tr> :
								processList.map((process, i) =>
									<tr key={i}>
										<td className="flex px-5 py-3 border-b dark:border-darkmode-300 border-l border-r border-t">
											{process.process}
											<span className='flex justify-end items-center w-full mt-3 sm:w-auto sm:ml-auto sm:mt-0'>
												<Button rounded className="mx-2" variant="outline-success" onClick={() => handleEditClick(process)}>Edit</Button>{' '}
												<Button rounded className="mx-2" variant="outline-danger" onClick={(e: any) => handleDeleteClick(e, process)}>Delete</Button>
											</span>
										</td>
									</tr>
								)}</tbody>
						</table>
					</div>
				</div>
			</div>
			<Dialog open={deleteConfirmationModal} initialFocus={deleteButtonRef} onClose={() => { setDeleteConfirmationModal(false); }}>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="Trash2" className="w-16 h-16 mx-auto mt-3 text-danger" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">
							Delete process {process.process}? <br />
							This process cannot be undone.
						</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button variant="outline-secondary" onClick={() => { setDeleteConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
						<Button variant="danger" className="w-24" ref={deleteButtonRef} onClick={deleteprocess} >Delete</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
			<Dialog open={updateConfirmationModal} initialFocus={updateButtonRef} onClose={() => { setUpdateConfirmationModal(false); }}>
				<Dialog.Panel>
					<div className="p-5 text-center">
						<Lucide icon="Edit" className="w-16 h-16 mx-auto mt-3 text-danger" />
						<div className="mt-5 text-3xl">Are you sure?</div>
						<div className="mt-2 text-slate-500">
							{btnText} process {oldProcess} &gt; {process.process}? <br />
							This process cannot be undone.
						</div>
					</div>
					<div className="px-5 pb-8 text-center">
						<Button variant="outline-secondary" type="button" onClick={() => { setUpdateConfirmationModal(false); }} className="w-24 mr-1">Cancel</Button>
						<Button variant="success" type="button" className="text-white w-24" ref={updateButtonRef} onClick={addUpdateProcess} >{btnText}</Button>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);
}

export default Main;