import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Litepicker from "../../../base-components/Litepicker";
import { FormLabel, FormInput, FormTextarea, FormInline, } from "../../../base-components/Form";
import Notification from "../../../base-components/Notification";
import Lucide from "../../../base-components/Lucide";
import Toastify from "toastify-js";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/store";
import Button from "../../../base-components/Button";
import { Dialog } from "../../../base-components/Headless";
import { FormSelect } from "../../../base-components/Form";
import { useForm } from "react-hook-form";
import './client.css'
import { useLoader } from "../../../base-components/Loader";

function Main() {
	const { showLoader, hideLoader } = useLoader();
	const navigate = useNavigate();
	const { planid } = useParams();
	const eventId = useSelector((state: RootState) => state.eventId.eventId);

	const dateRange1 = useSelector((state: RootState) => state.dateRange.dateRange ?? "");

	const [dateRange, setDateRange] = useState(dateRange1);
	const [invalidAlert, setInvalidAlert] = useState(false);
	const [castes, setCastes] = useState([]);
	
	
	const [btnText, setBtnText] = useState('Add Expenses');
	
	const [records, setRecords] = useState([])
	const [showList, setShowList] = useState(true);
	const [sendWAModalOpen, setSendWAModalOpen] = useState(false)
	const [type, setType] = useState('')
	const [expType, setExpType] = useState('')
	const [expenseName, setExpenseName] = useState<any>()
	const [currDate, setCurrDate] = useState(formatDateWithIntl(new Date()));
	function formatDateWithIntl(date: Date): string { return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date); }
	const initClient = { description: '', amt: 0 }
	const [expenses, setExpenses] = useState(initClient)
	const schema = yup.object().shape({
		//id: yup.number(),
		/*name: yup.string().trim().required().min(2),*/
		description: yup.string().required().min(4),
		amt: yup.number().required('number is required'),
			
	}).required();

	const { register, trigger, formState: { errors }, } = useForm({//defaultValues: client,
		criteriaMode: 'all', mode: "onSubmit", shouldFocusError: true,
		values: expenses, reValidateMode: 'onChange',
		resolver: yupResolver(schema),
	})

	

	const handleChange = (e) => {
		const name = e.target.value
		setExpenses((recentData) => ({ ...recentData, [e.target.name]: name }))
		
	}

	const getdata = async () => {
		if (planid) {
			try {
				const f = await fetch(`/api/expenses/${planid}`)
				if (f.ok) {
					const d = await f.json();
					setExpType(d.i.expenseId)
					setCurrDate(d.i.date)
					console.log(d.i)
					setExpenses({ ...expenses, amt: d.i.amount, description: d.i.narration})
					setBtnText('Update Plan')
				} else console.error(f.status, f.statusText)
			} catch (e) { } finally {
				hideLoader()
			}
		}
	}
	function getFormValuesAsJson(form: HTMLFormElement): any {
		const formData = new FormData(form);
		const values: any = {};

		for (const entry of formData.entries()) {
			const [name, value] = entry;
			if (name === '') continue;
			if (Object.prototype.hasOwnProperty.call(values, name)) {
				if (typeof values[name] === 'string') values[name] = [values[name], value];
				else values[name].push(value);
			} else values[name] = value;
		}
		return values;
	}

	const onSubmit2 = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			const result = await trigger()
			if (result) {
				showLoader()
				const updatedExpenses = getFormValuesAsJson(event.target);
				setExpenses(updatedExpenses);		
				const cl = { expenseId: expType, amt: expenses.amt, narration: expenses.description, date: currDate, id: planid }
				//console.log(cl)
				const d = JSON.stringify(cl)
				const f = await fetch('/api/expenses', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
				if (f.ok) {
					const data = await f.json();
					/*console.log("success", data);*/
					setInvalidAlert(true);
				} else console.error(f.status, f.statusText)
			} else console.log('validation error')
		} catch (error) { console.error(error) } finally { hideLoader() }
	}


	const onSubmitType = async () => {
		//event.preventDefault()
		try {
			
			
				showLoader()
				//const updatedExpenses = getFormValuesAsJson(event.target);
				//setType(updatedExpenses);		
				const cl = { expensename: type }			
				const d = JSON.stringify(cl)
				const f = await fetch('/api/expense-master', { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: d, })
				if (f.ok) {
					const data = await f.json();
					getCategories()
					//console.log("success", data);
				} else console.error(f.status, f.statusText)
			
		} catch (error) { console.error(error) } finally { hideLoader() }
	}
	async function getCategories() {
		try {
			
			showLoader()
			const f = await fetch('/api/expense-master-list')
			if (f.ok) {
				const r = await f.json()
				if (r.e) alert(r.d)
				else {
					setExpenseName(r.d)
					console.log(expenseName);
				}
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}
	useEffect(() => {
		getCategories()
		//async function getCategories() {
		//	try {

		//		showLoader()
		//		const f = await fetch('/api/expense-master-list')
		//		if (f.ok) {
		//			const r = await f.json()
		//			if (r.e) alert(r.d)
		//			else {
		//				setExpenseName(r.d)
		//				console.log(expenseName);
		//			}
		//		} else console.error(f.status, f.statusText)
		//	} catch (e) { console.error(e) } finally { hideLoader() }
		//}
		//useEffect(() => {
		//	getCategories()
			getdata();
		
	}, [])

	

	

	return (
		<>
			
			<div className="flex items-center mt-8 intro-y">
				<h1 className="mr-auto text-lg font-medium">Expenses</h1>
				<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
					<Button variant="primary" className="mr-2 shadow-md" onClick={() => { setSendWAModalOpen(true) }} >Add Expenses Type</Button>
					<Button variant="primary" className="mr-2 shadow-md" onClick={() => navigate('/finance/expenses-list')} >Expenses List</Button>
				</div>
			</div>
			<div className="grid grid-cols-12 gap-6 mt-5">
				<div className="col-span-12 intro-y lg:col-span-12">
					<div className="p-5 intro-y box">
						<form className="validate-form" onSubmit={onSubmit2}>
							<div className="relative my-3 text-slate-500">
								<Lucide icon="Calendar" className="absolute inset-y-0 left-0 z-10 w-4 h-4 my-auto ml-3" />
								<Litepicker className="pl-10 sm:w-56 !box" value={currDate} onChange={setCurrDate}
									options={{
										autoApply: false, showWeekNumbers: false, format: 'DD/MM/YYYY',
										dropdowns: { minYear: (new Date()).getFullYear(), maxYear: (new Date()).getFullYear() + 10, months: true, years: true, },
									}} />
							</div>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Expenses Name
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								{/*<FormInput id='fiName' {...register("name")} type="text" name="name" value={expenses.name} placeholder="Enter Expenses Name"*/}
								{/*	onChange={handleChange} className={clsx({ "border-danger": errors.name })} autoComplete='off' />*/}
								<FormSelect id='sCat' onChange={(e) => setExpType(e.target.value)} name='categoryId' value={expType} aria-label="Default select example">
									<option value='0'>Select Type</option>
									{(expenseName != undefined && expenseName && expenseName.length > 0) ? expenseName.map((cat: any) => (
										<option value={cat.expenseId} key={cat.expenseId}>{cat.expenseName}</option>
									)) : <option>Nothing to show</option>}
								</FormSelect>
							</div>
							<div className="input-form mb-5">
								<FormLabel htmlFor="fiName" className="flex flex-col w-full sm:flex-row"> Expenses Amount
									{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">Required, at least 2 characters</span>*/}
								</FormLabel>
								<FormInput id='fiName' {...register("amt")} type="text" name="amt" value={expenses.amt} placeholder="Enter Expenses amt"
									onChange={handleChange} className={clsx({ "border-danger": errors.amt })} autoComplete='off' />
								{errors.amt && (<div className="mt-2 text-danger">{typeof errors.amt.message === "string" && errors.amt.message}</div>)}
							</div>
							
							<div className="col-span-12 intro-y lg:col-span-6">
								<div className="input-form">
									<FormLabel className="flex flex-col w-full sm:flex-row">Description
										{/*<span className="mt-1 text-xs sm:ml-auto sm:mt-0 text-slate-500">*/}
										{/*	Required at least 5 character*/}
										{/*</span>*/}
									</FormLabel>
									<FormTextarea {...register("description")} value={expenses.description}
										onChange={handleChange} maxLength={200} className={clsx({ "border-danger": errors.description })}
										placeholder="Enter Duration" />
									{errors.description && (<div className="mt-2 text-danger"> {typeof errors.description.message === "string" && errors.description.message} </div>)}
								</div>
							</div>
						
							
							<Button variant="primary" type="submit" className="m-2">{btnText}</Button>
						</form>
					</div>
					<Dialog id='failed-notification-content' staticBackdrop open={invalidAlert} onClose={() => { setInvalidAlert(false); }} >
						<Dialog.Panel className="px-5 py-10">
							<div className="text-center">
								<div className="mb-5">{btnText} successfully..!</div>
								<Button type="button" variant="primary" className="w-24 mr-2" onClick={() => {
									navigate('/finance/expenses-list')
									setInvalidAlert(false)
								}}>Ok</Button>
							</div>
						</Dialog.Panel>
					</Dialog>				
				</div>
			</div>
			<Dialog open={sendWAModalOpen} onClose={() => { setSendWAModalOpen(false) }}>
				<Dialog.Panel>
					<Dialog.Title>
						<h2 className="mr-auto text-base font-medium">Expenses</h2>
						<Button className="w-24 ml-auto" onClick={() => { setSendWAModalOpen(false) }}><Lucide icon='XCircle' /></Button>
					</Dialog.Title>
					<div className="mx-5 my-3">
						<label className='font-medium'>Expenses Type:</label>
						<FormInput placeholder='Enter type' className='mb-5' onChange={(e) => setType(e.target.value)} value={type} />
					</div>
					<Dialog.Footer className='mt-1'>
						<Button type="button" variant="primary" className=" mr-2"
							onClick={() => {
								onSubmitType();
								setSendWAModalOpen(false)
							}}> Add Type</Button>
					</Dialog.Footer>
				</Dialog.Panel>
			</Dialog>
		</>
	);
}

export default Main;