import { NavLink } from "react-router-dom";

function Main() {
	return (
		<>
			<div className='w-screen h-screen overflow-hidden'>
				<h2 className='text-white text-4xl font-medium leading-none mb-5 mt-5'>My Cater</h2>
				<div className='flex'>
					<div className='ml-auto mr-20'>
						<NavLink to='/auth' className='text-white mx-5'>Login</NavLink>
						<NavLink to='/home' className='text-white mx-5'>Template</NavLink>
						<NavLink to='/admin' className='text-white mx-5'>Admin</NavLink>
						<NavLink to='/user' className='text-white mx-5'>User</NavLink>
						<NavLink to='/vendor/1/abc' className='text-white mx-5'>Vendor</NavLink>
					</div>
				</div>
				<div className='flex justify-center items-center'>
					<div className='text-center'>
						<h2 className='text-white text-3xl font-medium leading-none my-5'>Catering Software</h2>
						<h2 className='text-white text-2xl font-medium leading-none my-5'>React+Vite+TS+.Net 8 Core</h2>
					</div>
				</div>
			</div>

		</>
	);
}

export default Main;
