import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface UserState {
	tokenType: string;
	accessToken: string;
	expiresIn: number;
	refreshToken: string;
}

const initialState: UserState = {
	tokenType: '',//localStorage.getItem("tokenType") ?? 
	accessToken: '',//localStorage.getItem("accessToken") ?? 
	expiresIn:  0,//Number(localStorage.getItem("expiresIn")) ||
	refreshToken:  '',//localStorage.getItem("refreshToken") ??
}
export const userSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<UserState>) => {
			const user = action.payload
			state.tokenType = user.tokenType;
			state.accessToken = user.accessToken;
			state.expiresIn = Number(user.expiresIn);
			state.refreshToken = user.refreshToken;

			localStorage.setItem("tokenType", user.tokenType);
			localStorage.setItem("accessToken", user.accessToken);
			localStorage.setItem("expiresIn", user.expiresIn.toString());
			localStorage.setItem("refreshToken", user.refreshToken);
		},
		loadUser: (state, action: PayloadAction<UserState>) => {
			const user = action.payload
			state.tokenType = user.tokenType;
			state.accessToken = user.accessToken;
			state.expiresIn = Number(user.expiresIn);
			state.refreshToken = user.refreshToken;

			localStorage.setItem("tokenType", user.tokenType);
			localStorage.setItem("accessToken", user.accessToken);
			localStorage.setItem("expiresIn", user.expiresIn.toString());
			localStorage.setItem("refreshToken", user.refreshToken);
		},
		logout: (state) => {
			console.log('logout()')
			localStorage.clear();

			state.tokenType = '';
			state.accessToken = '';
			state.expiresIn = 0;
			state.refreshToken = '';
		}
	},
});

export const { setUser, logout } = userSlice.actions;

export const selectTokenType = (state: RootState) => state.auth.tokenType;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectExpiresIn = (state: RootState) => state.auth.expiresIn;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;

export default userSlice.reducer;

//import { createSlice, PayloadAction } from "@reduxjs/toolkit";
//import { RootState } from "./store";

//interface UserState {
//	tokenType: string;
//	accessToken: string;
//	expiresIn: number;
//	refreshToken: string;
//}

//const initialState: UserState = {
//	tokenType: localStorage.getItem("tokenType") ?? '',
//	accessToken: localStorage.getItem("accessToken") ?? '',
//	expiresIn: Number(localStorage.getItem("expiresIn")) ?? 0,
//	refreshToken: localStorage.getItem("refreshToken") ?? '',
//};

//export const userSlice = createSlice({
//	name: "auth", initialState,
//	reducers: {
//		setUser: (state, action: PayloadAction<any>) => {
//			//console.log(action.payload)
//			state.tokenType = action.payload.tokenType;
//			state.accessToken = action.payload.accessToken;
//			state.expiresIn = Number(action.payload.expiresIn);
//			state.refreshToken = action.payload.refreshToken;

//			localStorage.setItem("tokenType", action.payload.tokenType);
//			localStorage.setItem("accessToken", action.payload.accessToken);
//			localStorage.setItem("expiresIn", action.payload.expiresIn);
//			localStorage.setItem("refreshToken", action.payload.refreshToken);
//		},
//		loadUser: (state, action: PayloadAction<any>) => {
//			//console.log(action.payload)
//			state.tokenType = action.payload.tokenType;
//			state.accessToken = action.payload.accessToken;
//			state.expiresIn = Number(action.payload.expiresIn);
//			state.refreshToken = action.payload.refreshToken;

//			localStorage.setItem("tokenType", action.payload.tokenType);
//			localStorage.setItem("accessToken", action.payload.accessToken);
//			localStorage.setItem("expiresIn", action.payload.expiresIn);
//			localStorage.setItem("refreshToken", action.payload.refreshToken);
//		},
//		logout: (state) => {
//			localStorage.clear()

//			state.tokenType = null;
//			state.accessToken = null;
//			state.expiresIn = null;
//			state.refreshToken = null;
//		}
//	},
//});

//export const { setUser, logout } = userSlice.actions;

//export const selectTokenType = (state: RootState) => {
//	if (localStorage.getItem("tokenType") === null) { localStorage.setItem("tokenType", ""); }
//	return state.auth.tokenType;
//};
//export const selectAccessToken = (state: RootState) => {
//	if (localStorage.getItem("accessToken") === null) { localStorage.setItem("accessToken", ""); }
//	console.log(state.auth ?'True':'False')
//	return state.auth ? state.auth.accessToken : localStorage.getItem("accessToken");
//};
//export const selectExpiresIn = (state: RootState) => {
//	if (localStorage.getItem("expiresIn") === null) { localStorage.setItem("expiresIn", ""); }
//	return state.auth.expiresIn;
//};
//export const selectRefreshToken = (state: RootState) => {
//	if (localStorage.getItem("refreshToken") === null) { localStorage.setItem("refreshToken", ""); }
//	return state.auth.refreshToken;
//};

//export default userSlice.reducer;