import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../../../assets/css/tabulator-edit.css';
import Button from "../../../base-components/Button";
import { RootState } from "../../../stores/store";
import RawMaterialDay from "./component/RawMaterialDay";
import './hide-number-arraws.css';
import { useLoader } from "../../../base-components/Loader";

export default function Main() {
	const { showLoader, hideLoader } = useLoader();

	const nav = useNavigate()
	const eventId = useSelector((state: RootState) => state.eventId.eventId);

	const [data, setData] = useState([]);
	const [processes, setProcesses] = useState([])

	const fetchRawMaterialList = async () => {
		try {
			showLoader()
			const f = await fetch(`/api/raw_material_list/${eventId}`)
			if (f.ok) {
				const d = await f.json()
				console.log(d)
				if (d.e) alert("Error: " + d.err);
				else {
					setData(d.d.list);//const processesFormatted = d.map(item => item);
					setProcesses(d.d.process);
				}
			} else alert("Status code: " + f.status + ' ' + f.statusText);
		} catch (error) { console.error('Error fetching data:', error) } finally { hideLoader() }
	}

	useEffect(() => {
		if (eventId == null) nav('/admin')
		else fetchRawMaterialList()
	}, []);

	const refreshData = () => { setData([...data]) }

	//const navigate = useNavigate();
	const save = async () => {
		try {
			showLoader()
			const f = await fetch(`/api/save_rml/${eventId}`, { method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data), })
			if (f.ok) {
				const res = await f.json()
				console.log(res)
				nav('/admin/total-material-list')
			} else console.error(f.status, f.statusText)
		} catch (e) { console.error(e) } finally { hideLoader() }
	}

	return <>
			<div className="flex flex-col items-center mt-8 intro-y sm:flex-row">
				<h2 className="mr-auto text-lg font-medium">Raw Material List</h2>
				<div className="flex w-full mt-4 sm:w-auto sm:mt-0">
					{/*<Menu className="ml-auto sm:ml-0">*/}
					{/*	<Menu.Button as={Button} className="px-2 font-normal !box">*/}
					{/*		<span className="flex items-center justify-center w-5 h-5"><Lucide icon="Plus" className="w-4 h-4" /></span>*/}
					{/*	</Menu.Button>*/}
					{/*	<Menu.Items className="w-40">*/}
					{/*		<Menu.Item><Lucide icon="FilePlus" className="w-4 h-4 mr-2" /> New Category</Menu.Item>*/}
					{/*		<Menu.Item><Lucide icon="UserPlus" className="w-4 h-4 mr-2" /> New Group</Menu.Item>*/}
					{/*	</Menu.Items>*/}
					{/*</Menu>*/}
				</div>
			</div>
			<div className="p-5 mt-5 intro-y box">
				{data.map((rmd: RawMaterialData, i: number) => (<RawMaterialDay processes={processes} rmdIndex={i} rawMaterialData={rmd} key={i} refreshData={refreshData} />))}
				<div className='flex'>
					<Button variant="primary" className="my-5 mx-auto shadow-md"
						onClick={() => {
							setData([...data]);
							save();
						}} >Save and Proceed</Button>
				</div>
			</div>
		</>
}
